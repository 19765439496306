import firebase from 'firebase/app';
import 'firebase/firestore';

let db = null;

const firebaseConfig = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  projectId: process.env.PROJECT_ID,
};

export function initializeDB() {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app(); // if already initialized, use that one
  }

  db = firebase.firestore();
}

export function addUser(data, callback) {
  if (data.way === 'code') {
    db.collection('users').where('way', '==', 'code').where('code', '==', data.code).get()
      .then(querySnapshot => {
        if (querySnapshot.docChanges().length === 0) {
          db.collection('users').add(data)
            .then(() => {
              callback(true, null);
            })
            .catch(() => {
              callback(false, 'error');
            });
        } else {
          callback(false, 'codeExists');
        }
      });
  } else if (data.way === 'question') {
    db.collection('users').where('way', '==', 'question').where('email', '==', data.email).get()
      .then(querySnapshot => {
        if (querySnapshot.docChanges().length === 0) {
          db.collection('users').add(data)
            .then(() => {
              callback(true, null);
            })
            .catch(() => {
              callback(false, 'error');
            });
        } else {
          callback(false, 'emailUsed');
        }
      });
  }
}

export function getUsers(callback) {
  initializeDB();
  const data = [];
  db.collection('users').get().then(query => {
    query.forEach(doc => {
      data.push(doc.data());
    });
    callback(true, data);
  }).catch(error => {
    callback(false, error);
  });
}
